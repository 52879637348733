// ======================================================================================
// Override fix temporally
// Remove this file and import in App.scss after complete refactoring

.password-update-page,
.password-recovery-page,
.existing-accounts-page,
.id-finder-page {
  .section {
    margin: 0px;
    padding: 0 0 10px;

    &:last-child {
      margin-bottom: 10px;
    }

    .component {
      display: block;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .page-layout .description {
    padding-left: 0;
    background: none;
  }
  word-break: keep-all;

  .inner-contents,
  .inner-contents02 {
    padding: 20px 20px 30px;
    // padding: 36px 48px 36px 50px;
  }
  .registration-components .error {
    display: block;
  }

  input {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    &[type='text'],
    &[type='password'] {
      margin: 10px 0 5px;
      padding: 0 1em;
      width: 100%;
      height: 3.76em;
      font-size: 1em;
      color: #525252;
      background-color: #e6e6e6;
      background-color: rgba(61, 61, 61, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  .captcha-container {
    max-width: 300px;
    margin: 0 auto 0.75em !important;
  }
}
