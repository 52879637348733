.parent-consents-page {
  .list {
    .top-space {
      margin-top: 30px;
    }

    .consent {
      list-style: none;
      line-height: 1.5em;
      margin-bottom: 2em;
    }

    .parent-info{
      .section {
        margin: 25px 10px 10px 10px;
        padding: 0 30px 20px 40px;
        text-align: left;
        border: none;
        color: #000;
        background: url(//static.leagueoflegends.co.kr/common/img/common/tableline02.gif) no-repeat 30px bottom;

        .label {
          width: 11em;
          font-weight: bold;
          font-size: 12px;
          display: inline-block;
          vertical-align: top;
          padding-top: 0.4em;
        }

        .component {
          display: inline-block;
        }
      }
      .section:last-child {
        background: none;
      }
    }
  }

}