.fraud-report-agreement {
  font-size: 12px;

  .answers {
    input[type="text"] {
      width: 15em;
      padding-left: 0;
      margin-bottom: 1em;
    }

    .question {
      margin: 1em 0 1em 1em;

      .notice {
        font-weight: bold;
        margin-bottom: 0.5em;
      }
    }
  }

  .tips {
    li {
      margin: 1em 0 1em 0;
    }
  }

  .confirm {
    font-weight: bold;
    margin: 0 0 1em 2em;

    .message {
      margin: 0 0 1em 0;
    }
  }

  .next {
    display: flex;
    justify-content: flex-end;
  }
}
