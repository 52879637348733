.fraud-report-register {
  .report {
    .survey {
      span {
        margin: 0 1em 0 1em;
      }
      span:first-child {
        margin: 0 1em 0 0;
      }

      .mandatory {
        color: red;
      }

      li {
        margin: 0.5em 0 1.5em 0;
      }

      .table {
        li {
          display: flex;
          .column {
            width: 5em;
          }
          align-items: center;
        }
      }
    }

    .morespace {
      margin: 10px 0 50px 0;
    }

    .question {
      margin: 0 0 0.5em 0;
      font-size: 12px;
      font-weight: bold;
      font-family: "NanumGothicBold";
      

      .comment {
        margin-top: 0.5em;
        color: rgb(100, 100, 100);
      }
    }
  }

  .champions {
    width: 15em;
  }

  .skins {
    width: 20em;
  }

  .numbers {
    width: 4em;
  }

  .line {
    width: 50em;
  }

  .appendix {
    width: 65em !important;
    min-height: 15em;
  }

  .submit {
    display: flex;

    div:first-child {
      margin-right: 20px;
    }
  }
}
