.recovery-page {
  .list {
    .top-space {
      margin-top: 30px;
    }

    .next {
      display: block;
      text-align: right;
    }
  }

  .section {
    margin: 25px 10px 10px 10px;
    padding: 0 30px 20px 3px;
    text-align: left;
    border: none;
    color: #000;
    background: url(//static.leagueoflegends.co.kr/common/img/common/tableline02.gif) no-repeat 0px bottom;

    .label {
      width: 9em;
      font-weight: bold;
      font-size: 12px;
      display: inline-block;
      vertical-align: top;
      padding-top: 0.4em;
    }

    .component {
      display: inline-block;
    }
  }

}