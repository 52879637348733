@import './override-recovery.scss';

.app {
  @font-face {
    font-family: 'NanumGothic';
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumGothic.eot);
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumGothic.eot?#iefix) format('embedded-opentype'),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumGothic.woff) format('woff');
  }
  @font-face {
    font-family: 'NanumGothicBold';
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumGothicBold.eot);
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumGothicBold.eot?#iefix) format('embedded-opentype'),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumGothicBold.woff) format('woff');
  }
  @font-face {
    font-family: 'NanumMyeongjo';
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjo.eot);
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjo.eot?#iefix) format('embedded-opentype'),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjo.woff) format('woff');
  }
  @font-face {
    font-family: 'NanumMyeongjoBold';
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjoBold.eot);
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjoBold.eot?#iefix) format('embedded-opentype'),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjoBold.woff) format('woff');
  }
}
